<template>
  <div :class="cols">
    <div class="giga-operations_widget">
        <div class="giga-widget_body">
            <div class="giga-operation_value">
                <label>{{ value }}<sup>{{symbol}}</sup></label>
                <span v-if="rate" :class="[(state == 'up' ? 'rate-up' : ''), (state == 'down' ? 'rate-down' : '')]">
                    <sup v-if="state == 'up'">
                        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 0L10.2631 8.25H0.73686L5.5 0Z" fill="#6CBE3E"/>
                        </svg>
                        +
                        </sup>
                    <sup v-if="state == 'down'">
                        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 0L10.2631 8.25H0.73686L5.5 0Z" fill="#6CBE3E"/>
                        </svg>
                        -
                    </sup>
                    {{ rate }}
                    <sup>{{symbol}}</sup>
                </span>
            </div>
            <div class="giga-operation_label">{{ label }}</div>
        </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: "breakingRateWidget",
  props: {
    value: {
      type: Number,
    },
    label: {
      type: String,
    },
    rate: {
      type: String,
    },
    state: {
      type: String,
    },
    cols:{
      type: String,
    },
    symbol:{
      type: String
    }
  },
  computed:{
    ...mapGetters('user', ['getUsers']),
  }
};
</script>
