<template>
  <div v-if="!breakingRate && !loading" class="row" style="width: 100%">
      <breaking-rate-widget :value="breakingRateData.technicalConditionsNotMet" :label="$t('technical_conditions_not_met')" symbol="%" cols="col-lg-3"></breaking-rate-widget>
      <breaking-rate-widget :value="breakingRateData.workNotDone" :label="$t('work_not_done')" symbol="%" cols="col-lg-3"></breaking-rate-widget>
      <breaking-rate-widget :value="breakingRateData.unableToComply" :label="$t('unable_to_comply')" symbol="%" cols="col-lg-3"></breaking-rate-widget>
      <breaking-rate-widget :value="breakingRateData.unassignedWork" :label="$t('unassigned_work')" symbol="%" cols="col-lg-3"></breaking-rate-widget>
  </div>
</template>
<script>
import breakingRateWidget from "./breakingRateWidget";
import {ReportingService} from "../../Reporting/services";
import {mapGetters} from "vuex";

export default {
  name: 'OperationWidget',
  components: { breakingRateWidget },
  data() {
    return {
      breakingRateData: {
        technicalConditionsNotMet: null,
        workNotDone: null,
        unableToComply: null,
        unassignedWork: null
      },
      breakingRate: false
    }
  },
  computed: {
    ...mapGetters('loader', { loading: 'getLoading' })
  },
  mounted() {
    this.breakingRate = true;
    ReportingService.getBreakingRateData()
        .then((data) => this.setBreakingRateData(data))
        .finally(() => this.breakingRate = false);
  },
  methods: {
    setBreakingRateData(data) {
      let res = data.filter(elm => elm.abandonReason === 'CONDITIONS_TECHNIQUES_NON_ATTEINTES');
      this.breakingRateData.technicalConditionsNotMet = res.length > 0 ? res[0].count : 0;
      res = data.filter(elm => elm.abandonReason === 'TRAVAUX_NON_REALISES');
      this.breakingRateData.workNotDone = res.length > 0 ? res[0].count : 0;
      res = data.filter(elm => elm.abandonReason === 'IMPOSSIBILITE_DE_MISE_EN_CONFORMITE');
      this.breakingRateData.unableToComply = res.length > 0 ? res[0].count : 0;
      res = data.filter(elm => elm.abandonReason === 'TRAVAUX_NON_CONFIES_CTR_LEYTON_0FEE');
      this.breakingRateData.unassignedWork = res.length > 0 ? res[0].count : 0;
    }
  }
}
</script>